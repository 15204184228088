import React from "react"
import { Flex, Text } from "theme-ui"

const SaleBadge = ({ text = "0%", small = false }) => {
  return (
    <Flex sx={{ position: "absolute", zIndex: 99, right: 15, top: 15 }}>
      <Text
        sx={{
          color: "#245437",
          position: "absolute",
          right: "18%",
          top: "37%",
          transform: "rotate(30deg)",
          fontSize: small ? "8px" : "inherit",
        }}
      >
        {text}
      </Text>
      <svg
        width={small ? "35" : "61"}
        height={small ? "35" : "62"}
        viewBox="0 0 61 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="30.9808"
          cy="31.0003"
          r="30"
          transform="rotate(30 30.9808 31.0003)"
          fill="#DBFF00"
        />
      </svg>
    </Flex>
  )
}

export default SaleBadge
